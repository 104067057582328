// src/components/LoadingScreen.jsx

import React, { memo } from 'react';
import './LoadingScreen.css'; // Importa los estilos que necesites

const LoadingScreen = memo(() => {
  return (
    <div className="loading-screen">
      <div className="loading-spinner"></div>
      <h2>Cargando...</h2>
    </div>
  );
});

export default LoadingScreen;
