// src/context/AuthContext.jsx

import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth, onAuthStateChanged, db } from '../firebase/firebase';
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  setDoc,
  serverTimestamp,
  updateDoc
} from 'firebase/firestore';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

// Mapeo de tipos de cuenta a meditaciones máximas por mes
const MAX_MEDITATIONS_PER_MONTH_MAP = {
  free: 5,
  premium: 60,
  super: 200,
  fundadoras: 21, // Añadido el nuevo tipo de cuenta
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [typeAccount, setTypeAccount] = useState('free'); // Estado para el tipo de cuenta
  const [meditationsThisMonth, setMeditationsThisMonth] = useState(0);
  const [loading, setLoading] = useState(true);
  const [language, setLanguage] = useState('es'); // Incluye el idioma si es necesario
  const [voice, setVoice] = useState('default'); // Nuevo estado para la voz
  const [meditationType, setMeditationType] = useState('default'); // Nuevo estado para el tipo de meditación
  const [hasSeenWelcome, setHasSeenWelcome] = useState(false); // Nuevo estado
  const [isCreator, setIsCreator] = useState(false);
  const [isSubscribing, setIsSubscribing] = useState(false);

  useEffect(() => {
    let unsubscribe;
    
    const initAuth = async () => {
      try {
        unsubscribe = onAuthStateChanged(auth, async (user) => {
          setCurrentUser(user);
          if (user) {
            try {
              await fetchUserData(user);
              await fetchMeditationsCount(user);
              await fetchHasSeenWelcome(user);
              await checkAccountExpiration(user); // Verificar si la cuenta ha expirado
            } catch (error) {
              console.error('Error fetching user data:', error);
              // Manejar el error de manera más amigable
              setTypeAccount('free');
              setMeditationsThisMonth(0);
            }
          } else {
            // Reset states when no user
            setTypeAccount('free');
            setMeditationsThisMonth(0);
            setLanguage('es');
            setVoice('default');
            setMeditationType('default');
            setHasSeenWelcome(false);
          }
          setLoading(false);
        });
      } catch (error) {
        console.error('Auth initialization error:', error);
        setLoading(false);
      }
    };

    initAuth();

    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, []);

  // Función para actualizar el tipo de cuenta del usuario
  const updateAccountType = async (accountType, expirationDate = null) => {
    if (!currentUser) return false;
    
    try {
      const userDocRef = doc(db, 'users', currentUser.uid);
      
      const updateData = {
        typeAccount: accountType
      };
      
      if (expirationDate) {
        updateData.accountExpirationDate = expirationDate;
      }
      
      await updateDoc(userDocRef, updateData);
      
      // Actualizar el estado local
      setTypeAccount(accountType);
      
      // Recalcular las meditaciones permitidas
      return true;
    } catch (error) {
      console.error('Error updating account type:', error);
      return false;
    }
  };

  // Función actualizada para verificar si la cuenta premium/super ha expirado
  const checkAccountExpiration = async (user) => {
    try {
      const userDocRef = doc(db, 'users', user.uid);
      const userDocSnap = await getDoc(userDocRef);
      
      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        
        // Si es una cuenta con fecha de expiración (premium, super, fundadoras)
        if (
          (userData.typeAccount === 'premium' || 
           userData.typeAccount === 'super' || 
           userData.typeAccount === 'fundadoras') && 
          userData.accountExpirationDate
        ) {
          const expirationDate = userData.accountExpirationDate.toDate();
          const now = new Date();
          
          // Si la fecha de expiración ha pasado, cambiar a cuenta free
          if (now > expirationDate) {
            console.log(`Cuenta ${userData.typeAccount} expirada, cambiando a free`);
            await updateDoc(userDocRef, {
              typeAccount: 'free',
              subscriptionExpired: true,
              updatedAt: serverTimestamp()
            });
            setTypeAccount('free');
          }
        }
      }
    } catch (error) {
      console.error('Error checking account expiration:', error);
    }
  };

  useEffect(() => {
    if (currentUser) {
      const checkCreatorStatus = async () => {
        try {
          const userDocRef = doc(db, 'users', currentUser.uid);
          const userDocSnap = await getDoc(userDocRef);
          
          console.log('User data:', userDocSnap.data()); // Para debugging
          
          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            setIsCreator(userData.isCreator === true);
          } else {
            console.log('No user document found');
            setIsCreator(false);
          }
        } catch (error) {
          console.error('Error checking creator status:', error);
          setIsCreator(false);
        }
      };
      
      checkCreatorStatus();
    } else {
      setIsCreator(false);
    }
  }, [currentUser]);

  // Función actualizada para obtener información de suscripción
  const fetchUserData = async (user) => {
    try {
      const userDocRef = doc(db, 'users', user.uid);
      const userDocSnap = await getDoc(userDocRef);
      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        setTypeAccount(userData.typeAccount || 'free'); // Establece 'free' si no existe
        setLanguage(userData.language || 'es'); // Establece 'es' si no existe
        setVoice(userData.voice || 'default'); // Establece 'default' si no existe
        setMeditationType(userData.meditationType || 'default'); // Establece 'default' si no existe
        
        // Verificar si tiene cuenta con suscripción activa
        if (userData.typeAccount === 'premium' || userData.typeAccount === 'super') {
          if (userData.accountExpirationDate) {
            const expirationDate = userData.accountExpirationDate.toDate();
            const now = new Date();
            
            // Verificar si la suscripción sigue activa
            if (now > expirationDate) {
              // Actualizar a 'free' si expiró la suscripción
              await updateDoc(userDocRef, {
                typeAccount: 'free',
                subscriptionExpired: true,
                updatedAt: serverTimestamp()
              });
              setTypeAccount('free');
            }
          }
        }
      } else {
        // Si el documento de usuario no existe, lo creamos con valores por defecto
        await setDoc(userDocRef, {
          email: user.email,
          displayName: user.displayName || '',
          typeAccount: 'free',
          language: 'es',
          voice: 'default',
          meditationType: 'default',
          createdAt: serverTimestamp()
        });
        setTypeAccount('free');
        setLanguage('es');
        setVoice('default');
        setMeditationType('default');
      }
      
      await fetchMeditationsCount(user);
      await fetchHasSeenWelcome(user);
      
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  // Función para obtener el conteo de meditaciones del mes actual
  const fetchMeditationsCount = async (user) => {
    try {
      const now = new Date();
      const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
      const startOfNextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);

      const meditationsRef = collection(db, 'meditations');
      const q = query(
        meditationsRef,
        where('userId', '==', user.uid),
        where('createdAt', '>=', startOfMonth),
        where('createdAt', '<', startOfNextMonth)
      );

      const querySnapshot = await getDocs(q);
      setMeditationsThisMonth(querySnapshot.size);
    } catch (error) {
      console.error('Error fetching meditations count:', error);
      // Opcional: Manejar el error de manera adecuada, por ejemplo, estableciendo un estado de error
    }
  };

  // Función para obtener si el usuario ha visto el pop-up de bienvenida
  const fetchHasSeenWelcome = async (user) => {
    try {
      const userDocRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const data = userDoc.data();
        setHasSeenWelcome(data.hasSeenWelcome || false);
      }
    } catch (error) {
      console.error('Error fetching hasSeenWelcome:', error);
      setHasSeenWelcome(false);
    }
  };

  // Función para incrementar el conteo de meditaciones (llamada después de una meditación exitosa)
  const incrementMeditationsCount = () => {
    setMeditationsThisMonth((prev) => prev + 1);
  };

  // Función para establecer directamente el conteo de meditaciones
  const setMeditationsCount = (count) => {
    setMeditationsThisMonth(count);
  };

  // Determina el máximo de meditaciones según el tipo de cuenta
  const MAX_MEDITATIONS_PER_MONTH = MAX_MEDITATIONS_PER_MONTH_MAP[typeAccount] || 5;

  // Función para iniciar proceso de suscripción
  const startSubscription = async (planId, price) => {
    if (!currentUser) return { success: false, error: 'No hay usuario autenticado' };
    
    setIsSubscribing(true);
    
    try {
      // Obtener el token de ID del usuario actual
      const idToken = await currentUser.getIdToken();
      
      // Llamar al endpoint de creación de suscripción
      const response = await fetch('/api/create-subscription', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`
        },
        body: JSON.stringify({
          planId,
          price
        })
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Error al crear la suscripción');
      }
      
      const data = await response.json();
      setIsSubscribing(false);
      
      return {
        success: true,
        data
      };
    } catch (error) {
      console.error('Error starting subscription:', error);
      setIsSubscribing(false);
      
      return {
        success: false,
        error: error.message || 'Error al procesar la suscripción'
      };
    }
  };

  // Función para verificar el estado de una suscripción
  const checkSubscriptionStatus = async () => {
    if (!currentUser) return null;
    
    try {
      const userDocRef = doc(db, 'users', currentUser.uid);
      const userDocSnap = await getDoc(userDocRef);
      
      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        
        // Si el usuario tiene tipo de cuenta premium o super
        if (userData.typeAccount === 'premium' || userData.typeAccount === 'super') {
          if (userData.accountExpirationDate) {
            const expirationDate = userData.accountExpirationDate.toDate();
            const now = new Date();
            
            // Calcular días restantes
            const daysRemaining = Math.ceil((expirationDate - now) / (1000 * 60 * 60 * 24));
            
            return {
              active: now < expirationDate,
              type: userData.typeAccount,
              expirationDate,
              daysRemaining: daysRemaining > 0 ? daysRemaining : 0
            };
          }
        }
      }
      
      return {
        active: false,
        type: 'free'
      };
    } catch (error) {
      console.error('Error checking subscription status:', error);
      return {
        active: false,
        type: 'free',
        error: error.message
      };
    }
  };

  const value = {
    currentUser,
    typeAccount,
    language,
    voice,
    meditationType,
    meditationsThisMonth,
    hasSeenWelcome,
    isCreator,
    loading,
    isSubscribing,
    updateAccountType,
    setHasSeenWelcome,
    incrementMeditationsCount,
    setMeditationsCount,
    setLanguage,
    setVoice,
    setMeditationType,
    startSubscription,
    checkSubscriptionStatus
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
