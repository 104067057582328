// src/App.jsx

import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import LoadingScreen from './components/LoadingScreen';

// Limpiador de elementos UI persistentes
const UICleaner = () => {
  const location = useLocation();
  
  // Efecto que se ejecuta al cambiar de ruta
  useEffect(() => {
    // Limpieza de flechas de select u otros elementos UI persistentes
    const cleanupUI = () => {
      // Ocultar cualquier flecha de selector que pueda haber quedado visible
      const arrowElements = document.querySelectorAll('.select-wrapper::after, .form-group::after');
      if (arrowElements) {
        arrowElements.forEach(el => {
          if (el) {
            el.style.opacity = '0';
            el.style.visibility = 'hidden';
          }
        });
      }
      
      // Forzar repintado del DOM
      document.body.style.overflow = document.body.style.overflow;
    };
    
    // Ejecutar limpieza
    cleanupUI();
    
    // También limpiar al desmontar el componente
    return cleanupUI;
  }, [location]);
  
  return null; // Este componente no renderiza nada
};

// Preload de componentes críticos
const Auth = lazy(() => import('./components/auth'));
const Main = lazy(() => import('./components/main'));
const Analytics = lazy(() => import('./components/analytics'));
const PrivateRoute = lazy(() => import('./components/PrivateRoute'));

// Carga perezosa de componentes secundarios
const CartillaAnimica = lazy(() => import('./components/CartillaAnimica'));
const WaitList = lazy(() => import('./components/WaitList'));
const Landing = lazy(() => import('./components/Landing'));
const Configuration = lazy(() => import('./components/Configuration'));
const Journal = lazy(() => import('./components/Journal'));
const CreatorDashboard = lazy(() => import('./components/creator/CreatorDashboard'));
const ProgramView = lazy(() => import('./components/programs/ProgramView'));

// Componentes de suscripción
const Subscription = lazy(() => import('./components/Subscription'));
const Checkout = lazy(() => import('./components/Subscription/Checkout'));
const SubscriptionSuccess = lazy(() => import('./components/Subscription/Success'));
const SubscriptionFailure = lazy(() => import('./components/Subscription/Failure'));

// Componente de fallback optimizado
const FallbackComponent = () => (
  <div style={{ 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center', 
    height: '100vh' 
  }}>
    <LoadingScreen />
  </div>
);

const App = () => {
  return (
    <Router>
      <UICleaner />
      <Suspense 
        fallback={<LoadingScreen />}
        onTimeout={() => <div>La carga está tardando más de lo esperado...</div>}
        timeout={5000}
      >
        <Routes>
          <Route
            path="/"
            element={
              <PrivateRoute fallback="/landing">
                <Suspense fallback={<LoadingScreen />}>
                  <Main />
                </Suspense>
              </PrivateRoute>
            }
          />
          <Route path="/landing" element={
            <Suspense fallback={<LoadingScreen />}>
              <Landing />
            </Suspense>
          } />
          <Route path="/result" element={
            <Suspense fallback={<LoadingScreen />}>
              <Analytics path="/result" />
            </Suspense>
          } />
          <Route path="/login" element={
            <Suspense fallback={<LoadingScreen />}>
              <Auth path="/login" />
            </Suspense>
          } />
          <Route path="/signup" element={
            <Suspense fallback={<LoadingScreen />}>
              <Auth path="/signup" />
            </Suspense>
          } />
          <Route path="/waitlist" element={
            <Suspense fallback={<LoadingScreen />}>
              <WaitList />
            </Suspense>
          } />
          <Route path="/cartilla" element={
            <Suspense fallback={<LoadingScreen />}>
              <CartillaAnimica />
            </Suspense>
          } />
          <Route path="/stats" element={
            <Suspense fallback={<LoadingScreen />}>
              <Analytics path="/stats" />
            </Suspense>
          } />
          <Route path="/config" element={
            <Suspense fallback={<LoadingScreen />}>
              <Configuration />
            </Suspense>
          } />
          <Route path="/journal" element={
            <Suspense fallback={<LoadingScreen />}>
              <Journal />
            </Suspense>
          } />
          <Route 
            path="/creadores" 
            element={
              <PrivateRoute creatorOnly={true}>
                <Suspense fallback={<LoadingScreen />}>
                  <CreatorDashboard />
                </Suspense>
              </PrivateRoute>
            } 
          />
          <Route path="/:programId" element={
            <Suspense fallback={<LoadingScreen />}>
              <ProgramView />
            </Suspense>
          } />
          <Route
            path="/subscription"
            element={
              <PrivateRoute fallback="/landing">
                <Suspense fallback={<LoadingScreen />}>
                  <Subscription />
                </Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path="/checkout/:plan"
            element={
              <PrivateRoute fallback="/landing">
                <Suspense fallback={<LoadingScreen />}>
                  <Checkout />
                </Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path="/subscription/success"
            element={
              <PrivateRoute fallback="/landing">
                <Suspense fallback={<LoadingScreen />}>
                  <SubscriptionSuccess />
                </Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path="/subscription/failure"
            element={
              <PrivateRoute fallback="/landing">
                <Suspense fallback={<LoadingScreen />}>
                  <SubscriptionFailure />
                </Suspense>
              </PrivateRoute>
            }
          />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
